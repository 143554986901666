import stgConstants from "../../config/config.stg";
const today = new Date().toLocaleDateString();
const isWindowDefined = typeof window !== "undefined"; // added this check so that getEnv can work in Playwright
const currentHost = isWindowDefined ? window.location.hostname : "localhost";
function checkCommitId() {
  if (typeof COMMITID === "undefined") {
    return "development";
  }
  return COMMITID;
}
const localStorageKey = `constants-${currentHost}-${today}-${checkCommitId()}`;
export const isLocalhost = !isWindowDefined
  ? true
  : window.location.hostname === "localhost";
export const isProduction =
  isWindowDefined && window.location.hostname === "learning.appliedmedical.com";
const isLMSPRODTEST =
  isWindowDefined &&
  window.location.hostname === "appliedmedlmsprodtest.z1.web.core.windows.net";
const QARegex = /appliedmedlms[0-9]?qa.z1.web.core.windows.net/;
const QA9Regex = /appliedmedlms9qa.z1.web.core.windows.net/;
const POCRegex = /appliedmedlms[0-9]?poc.z1.web.core.windows.net/;
export const isLMSQA =
  isWindowDefined && QARegex.test(window.location.hostname);
export const isLMSQA9 =
  isWindowDefined && QA9Regex.test(window.location.hostname);
export const isLMSPOC =
  isWindowDefined && POCRegex.test(window.location.hostname);
export const isLMSDEV =
  isWindowDefined &&
  window.location.hostname === "appliedmedlmsdev.z1.web.core.windows.net";

// ****** HELPERS ****** //

export const serveConstants = () => {
  const savedConstants = isWindowDefined
    ? JSON.parse(localStorage.getItem(localStorageKey))
    : JSON.parse(
        `{"AUTHBASEURL":"https://api.appliedmed.com/AuthQA","AUTHENTICATEURL":"https://apistg.appliedmed.com/MyApplied","BASEURL":"https://lms-stg.appliedmed.com/LMS2","BASEURL_QS":"https://lms-stg.appliedmed.com/LMS","EVENTMANAGEMENTURL":"https://lmsapp-stg.appliedmed.com/ILT/Events","GRAPHQL_ENDPOINT":"https://corp-feserverless-funcapp-prod-eastus2.azurewebsites.net/api","ADMINREPORTSURL":"https://app.powerbi.com/groups/me/apps/e0ac846f-10d8-4e20-ad1f-d70bf56f665a/reports/7ce950e3-577d-4f52-b078-f92dc6de67be/ReportSection?ctid=6517ef53-434f-418d-a53c-e6c0fb9c3c8e&experience=power-bi","MYTEAMREPORTSURL":"https://app.powerbi.com/groups/39d20cc2-ca3f-46b3-9789-8210e358beed/reports/c1aaabe3-ad9e-43a3-a84c-b677957d7f08/ReportSection6817372786022b626eb0","ADMINTRANSCRIPTSURL":"https://bpmqa.appliedmedical.com/Primary/H/?FlowAlias=LMS&ForceFormat=true&Location=Maximized&Chrome=Off","CONTENTURL":"http://webassets.appliedmedical.com/content","DASHBOARDURL":"https://lmsapp-stg.appliedmed.com/AppliedLMS/","DEVMODE":false}`
      );

  if (isProduction) {
    const production = require("../../config/config.prod");
    return production;
  }

  if (isLocalhost && !savedConstants) {
    const env = require("../../config/config.stg");
    localStorage.setItem(localStorageKey, JSON.stringify(env));
    localStorage.setItem("currentEnv", "stg");
    return env;
  }

  if (isLMSQA9 && !savedConstants) {
    const env = require("../../config/config.pg");
    localStorage.setItem(localStorageKey, JSON.stringify(env));
    localStorage.setItem("currentEnv", "pg");
    return env;
  }

  if (isLMSQA && !savedConstants) {
    const env = require("../../config/config.stg");
    localStorage.setItem(localStorageKey, JSON.stringify(env));
    localStorage.setItem("currentEnv", "stg");
    return env;
  }

  if (isLMSDEV && !savedConstants) {
    const env = require("../../config/config.dev");
    localStorage.setItem(localStorageKey, JSON.stringify(env));
    localStorage.setItem("currentEnv", "dev");
    return env;
  }

  if (isLMSPOC && !savedConstants) {
    const env = require("../../config/config.dev");
    localStorage.setItem(localStorageKey, JSON.stringify(env));
    return env;
  }

  if (savedConstants) {
    return savedConstants;
  }

  //NO EXISTING SAVED CONSTANTS, NOT LOCALHOST OR QA1-9
  localStorage.setItem(localStorageKey, JSON.stringify(stgConstants));
  localStorage.setItem("currentEnv", "stg");
  return stgConstants;
};

// RETRIEVE
export const getEnv = api => {
  return serveConstants()[api];
};

// CHANGE ALL VARIABLES TO SELECTED ENVIRONMENT
export const changeEnvironments = env => {
  console.log("env:", env);
  const retrievedEndpoints = require(`../../config/config.${env}`);
  localStorage.setItem("currentEnv", env);
  localStorage.setItem(localStorageKey, JSON.stringify(retrievedEndpoints));
};

// CHANGE INDIVIDUAL VARIABLE ENVIRONMENT //
export const updateSingleItemEnv = (target, env) => {
  let savedValues = JSON.parse(localStorage.getItem(localStorageKey));
  console.log("Retrieved savedValues: ", savedValues);
  const endpoints = require(`../../config/config.${env}`);
  savedValues[target] = endpoints[target];
  console.log("[EnvSwitcher]: Setting savedValues to: ", savedValues);
  localStorage.setItem(localStorageKey, JSON.stringify(savedValues));
};

// CHANGE INDIVIDUAL VARIABLE FOR NON-STRING VALUE (True/False/NaN/Null) //
export const updateConstantVal = (target, value) => {
  const savedValues = JSON.parse(localStorage.getItem(localStorageKey));
  savedValues[target] = value;
  localStorage.setItem(localStorageKey, JSON.stringify(savedValues));
};

// RESTORE TO DEFAULT //
export const restoreDefault = () => {
  localStorage.removeItem(localStorageKey, JSON.stringify(serveConstants()));
};

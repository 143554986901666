import instance from "./request";
import {
  checkExternalUserIfActive,
  setPages,
  setUserflags,
} from "./UserFunctions";

export let isAuthenticated = false;
export let RefreshToken;
export let JwtToken;
export let HasuraJwtToken;
export let isSignedInWithAuth0;

export const adminLevel = "LEVEL4";

/**
 * User Object data passed down from auth0
 */
export let user = {
  /** Returns additional data @returns {string} */
  additionalData: "",
  city: "",
  companyCode: "",
  companyName: "",
  costCenter: "",
  country: "",
  department: "",
  effectiveFrom: "",
  effectiveTo: "",
  email: "",
  /** Returns an array of strings that pertain to selectively display features in development @returns {Array<string>} */
  featureFlags: [""],
  firstName: "",
  /** Returns an array of strings that refers to calculated roles given to a user @returns {Array<string>}*/
  flags: [""],
  /** Returns an array of strings that refers to the groups a user is assigned to @returns {Array<string>}*/
  groups: [""],
  isImpersonated: "",
  lastName: "",
  location: "",
  managerId: 0,
  managerSource: "",
  /** Returns the original user's Id when proxying as another @returns {string}*/
  originalUserId: "",
  /** Returns the original user's Source when proxying as another @returns {string}*/
  originalUserSource: "",
  /** Returns an array of strings that refers to the pages a user has access to @returns {Array<string>}*/
  permissions: [""],
  /** Returns the user type of the user @returns {string} */
  source: "",
  state: "",
  subArea: "",
  subAreaName: "",
  /** Returns the time zone of the user @returns {string} */
  timeZone: "",
  /** The Id of the user */
  userId: 0,
  userName: "",
  /* Added properties to user object */
  /** Property that shows whether or not a user is active @returns {Boolean}*/
  isActive: true,
  /** Flag that checks if the user is a manager of other users */
  IsManager: false,
  /** Flag that will give access to proxy page */
  proxyAccess: false,
  /** Flag that shows whether or not a user is proxying as a different user */
  proxy: false,
};

export const setUserWithAuth0 = async (userInfo) => {
  let outputUser = { ...userInfo, IsManager: false, proxyAccess: false };

  /* Check if the user is currently proxying */
  if (userInfo?.originalUserId) {
    outputUser.proxy = true;
  }

  /* Set Boolean flags */
  setUserflags(outputUser, userInfo);

  if (outputUser.source === "ExternalUsers") {
    await checkExternalUserIfActive(outputUser);
  }

  // console.log(outputUser);
  await setPages(outputUser);
  user = outputUser;
};

export const setIsSignedInWithAuth0 = (string) => {
  isSignedInWithAuth0 = string;
};
export const isITorIS = () =>
  user.CostCenter === "101040" || user.CostCenter === "101045";

export const updateGlobalJWT = async (jwt) => {
  // console.log(`updateGlobalJWT:\n https://jwt.io/?value=${jwt}`);
  instance.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  JwtToken = jwt;
};

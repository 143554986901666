module.exports = {
  // base endpoints
  BASEURL: "https://lms-stg.appliedmed.com/LMS2",
  BASEURL_QS: "https://lms-stg.appliedmed.com/LMS",

  // events ILT
  EVENTMANAGEMENTURL: "https://lmsapp-stg.appliedmed.com/ILT/Events",

  // reports
  ADMINREPORTSURL:
    "https://app.powerbi.com/groups/me/apps/e0ac846f-10d8-4e20-ad1f-d70bf56f665a/reports/7ce950e3-577d-4f52-b078-f92dc6de67be/ReportSection?ctid=6517ef53-434f-418d-a53c-e6c0fb9c3c8e&experience=power-bi",
  MYTEAMREPORTSURL:
    "https://app.powerbi.com/groups/me/apps/e0ac846f-10d8-4e20-ad1f-d70bf56f665a/reports/cff4cd03-8fde-4d80-8f21-cc24814af277/ReportSection6817372786022b626eb0?ctid=6517ef53-434f-418d-a53c-e6c0fb9c3c8e",
  CERTIFICATIONREPORTSURL:
    "https://app.powerbi.com/groups/me/apps/e0ac846f-10d8-4e20-ad1f-d70bf56f665a/reports/33d614fd-9b1a-4267-8ff3-c048322e1a36/2a7da9788e0e45da06cd?ctid=6517ef53-434f-418d-a53c-e6c0fb9c3c8e&experience=power-bi",

  // decisions
  ADMINTRANSCRIPTSURL:
    "https://bpmqa.appliedmedical.com/h/?PageId=9afa94eb-8894-11ee-919a-005056bf3f1b",

  // misc
  CONTENTURL: "http://webassets.appliedmedical.com/content",
  DASHBOARDURL: "https://lmsapp-stg.appliedmed.com/AppliedLMS/",
  DEVMODE: false,
};

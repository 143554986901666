import { user } from "__shared/auth";
import { get, post } from "__shared/request";
import { getEnv } from "__utils/constantsUtil";
import React, { createContext, useContext, useEffect, useState } from "react";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [pendingNotifications, setPendingNotifications] = useState({
    QS_DOCS: false,
    LEARNING: false,
    CERTIFICATIONS_MANAGEMENT: false,
    CERTIFICATIONS: false,
  });

  const [isLoading, setIsLoading] = useState({
    QS_DOCS: false,
    LEARNING: false,
    CERTIFICATIONS_MANAGEMENT: false,
    CERTIFICATIONS: false,
  });

  /**
   * Fetches notification data for QS_DOCS
   * @returns {Promise<void>}
   */
  const fetchQS_DOCS = async () => {
    if (isLoading.QS_DOCS) return;

    setIsLoading((prev) => ({ ...prev, QS_DOCS: true }));

    try {
      const url = `${getEnv("BASEURL_QS")}/TeamMember/${
        user.userId
      }?filter=Pending`;
      const { data } = await get({ url });
      const hasPending = data.Assignments.length > 0;
      setPendingNotifications((prev) => ({ ...prev, QS_DOCS: hasPending }));
    } catch (error) {
      console.error(`Error fetching QS_DOCS notifications:`, error);
    } finally {
      setIsLoading((prev) => ({ ...prev, QS_DOCS: false }));
    }
  };

  /**
   * Fetches non-QS notification data for multiple types
   * @param {Array<'LEARNING'|'CERTIFICATIONS_MANAGEMENT'|'CERTIFICATIONS'>} types - The types of notifications to fetch
   * @returns {Promise<void>}
   */
  const fetchData = async (types) => {
    if (types.some((type) => isLoading[type])) return;

    const loadingState = types.reduce(
      (acc, type) => ({ ...acc, [type]: true }),
      {}
    );
    setIsLoading((prev) => ({ ...prev, ...loadingState }));

    try {
      // NOTE: The supported page code strings and their corresponding enum values accepted by the API are as follows:
      // CERTIFICATIONS -> 1: Certifications
      // CERTIFICATIONS_MANAGEMENT -> 2: CertificationsManagement
      // LEARNING -> 3: LearningPending
      // I am using the enum values instead of the page code strings.
      const pageCodes = types
        .map((type) => {
          switch (type) {
            case "LEARNING":
              return 3;
            case "CERTIFICATIONS_MANAGEMENT":
              return 2;
            case "CERTIFICATIONS":
              return 1;
            default:
              return null;
          }
        })
        .filter(Boolean);

      const url = `${getEnv("BASEURL")}/Team/PendingActions`;
      const { data } = await post({
        url,
        data: {
          source: user.source,
          sourceId: user.userId,
          pageCodes,
        },
      });

      const pendingState = types.reduce((acc, type) => {
        switch (type) {
          case "LEARNING":
            acc.LEARNING = data.LearningPending > 0;
            break;
          case "CERTIFICATIONS_MANAGEMENT":
            acc.CERTIFICATIONS_MANAGEMENT =
              data.CertificationsManagementPendingActions > 0;
            break;
          case "CERTIFICATIONS":
            acc.CERTIFICATIONS = data.CertificationsPending > 0;
            break;
          default:
            break;
        }
        return acc;
      }, {});

      setPendingNotifications((prev) => ({ ...prev, ...pendingState }));
    } catch (error) {
      console.error(`Error fetching notifications:`, error);
    } finally {
      const notLoadingState = types.reduce(
        (acc, type) => ({ ...acc, [type]: false }),
        {}
      );
      setIsLoading((prev) => ({ ...prev, ...notLoadingState }));
    }
  };

  /**
   * Fetches pending notifications based on the specified page codes
   * @param {Array<'LEARNING'|'QS_DOCS'|'CERTIFICATIONS_MANAGEMENT'|'CERTIFICATIONS'>} [pageCodes] - Optional array of page codes to fetch specific notifications. If not provided, fetches all applicable notifications
   * @returns {Promise<void>}
   */
  const fetchPendingNotifications = async (pageCodes) => {
    const canFetchDocs =
      (user.source === "AgencyTemps" || user.source === "TeamMembers") &&
      (!pageCodes || pageCodes.includes("QS_DOCS"));

    if (canFetchDocs) {
      fetchQS_DOCS();
    }

    const typesToFetch = [];
    if (!pageCodes || pageCodes.includes("LEARNING")) {
      typesToFetch.push("LEARNING");
    }
    if (
      user.permissions.includes("CERTIFICATIONS_MANAGEMENT") &&
      (!pageCodes || pageCodes.includes("CERTIFICATIONS_MANAGEMENT"))
    ) {
      typesToFetch.push("CERTIFICATIONS_MANAGEMENT");
    }
    if (
      user.source === "TeamMembers" &&
      (!pageCodes || pageCodes.includes("CERTIFICATIONS"))
    ) {
      typesToFetch.push("CERTIFICATIONS");
    }

    if (typesToFetch.length > 0) {
      fetchData(typesToFetch);
    }
  };

  useEffect(() => {
    fetchPendingNotifications();
  }, []);

  return (
    <NotificationContext.Provider
      value={{ pendingNotifications, fetchPendingNotifications }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;

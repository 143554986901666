import React, { useState, useContext, useEffect, createContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { setUserWithAuth0, updateGlobalJWT, user } from "__shared/auth";
import { opts } from "../../config/auth0.config";
import { del, get } from "__shared/request";
import { APPCENTER_URL, appCode, envCode } from "../../config/auth0.config";

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const {
    user: auth0User,
    loginWithRedirect,
    logout: auth0Logout,
    getAccessTokenSilently,
  } = useAuth0();
  const [myUser, setMyUser] = useState(user);
  const [settingUserData, setSettingUserData] = useState(true);
  const isTest =
    localStorage.getItem("playwrightTest") === "true" ? true : false;

  useEffect(() => {
    async function setUserData() {
      if (auth0User?.["https://auth.appliedmedical.com/identity/userData"]) {
        const userData =
          auth0User["https://auth.appliedmedical.com/identity/userData"];
        // console.log("user data found___", userData);
        if (userData) {
          const token = await getAccessTokenSilently({
            authorizationParams: opts,
          });
          await updateGlobalJWT(token);
          await setUserWithAuth0(userData);
          setMyUser(user);
          setSettingUserData(false);
        }
      } else {
        setSettingUserData(false);
      }
    }

    setUserData();
  }, [auth0User]);

  async function logout() {
    if (user.proxy) {
      const { data } = await get({
        url: `${APPCENTER_URL}/Impersonation/Search/${appCode}/${envCode}`,
      });
      const filteredSessions = data.results.filter(
        (elem) => elem.originalUserAuthProviderId === auth0User.sub
      );
      if (filteredSessions.length > 0) {
        for (let proxy of filteredSessions) {
          await del({
            url: `${APPCENTER_URL}/Impersonation`,
            params: { id: proxy.id },
          }).catch((err) => {
            console.log(err);
          });
        }
      }
    }
    auth0Logout({
      logoutParams: {
        returnTo: window.location.origin + (isTest ? "" : "/Welcome"),
      },
    });
  }

  return (
    <UserContext.Provider
      value={{
        loginWithRedirect,
        myUser,
        logout,
        settingUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
